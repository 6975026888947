module.exports = [{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"enableCustomId":true,"isIconAfterHeader":true,"icon":"<svg aria-hidden=\"true\" height=\"16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"/></svg>\n","offsetY":0,"className":"anchor"},
    },{
      plugin: require('../node_modules/@dvcorg/gatsby-theme-iterative/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":700,"withWebp":true,"quality":90,"loading":"auto","linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","withAvif":false,"decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://0d66bb5685064e97af2ef160350ea184@o349038.ingest.sentry.io/6213045","environment":"production","release":"78f336888ebeeed72c043f7a66e36c59eff22c1f","enabled":true,"ignoreErrors":["ChunkLoadError","top.GLOBALS","originalCreateNotification","canvas.contentDocument","MyApp_RemoveAllHighlights","http://tt.epicplay.com","Can't find variable: ZiteReader","jigsaw is not defined","ComboSearch is not defined","http://loading.retry.widdit.com/","atomicFindClose","fb_xd_fragment","bmi_SafeAddOnload","EBCallBackMessageReceived","conduitPage"],"denyUrls":[{},{},{},{},{},{},{},{},{}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"MLEM","short_name":"MLEM","icon":"src/images/icon-512x512.png","start_url":"/","background_color":"#F5F7F8","theme_color":"#F5F7F8","display":"standalone","icons":[{"src":"/apple-touch-icon-48x48.png","sizes":"48x48","type":"image/png"},{"src":"/apple-touch-icon-72x72.png","sizes":"72x72","type":"image/png"},{"src":"/apple-touch-icon-96x96.png","sizes":"96x96","type":"image/png"},{"src":"/apple-touch-icon-144x144.png","sizes":"144x144","type":"image/png"},{"src":"/apple-touch-icon.png","sizes":"180x180","type":"image/png"},{"src":"/apple-touch-icon-192x192.png","sizes":"192x192","type":"image/png"},{"src":"/apple-touch-icon-256x256.png","sizes":"256x256","type":"image/png"},{"src":"/apple-touch-icon-384x384.png","sizes":"384x384","type":"image/png"},{"src":"/apple-touch-icon-512x512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"7fa6c5eebdb6d0b7793d547ca2b5020d"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
